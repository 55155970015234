import store from '../store'

export default {
    baseUrl: process.env.VUE_APP_API_URL,
    getUser () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        return window.axios.get(this.baseUrl + 'api/user', config)
    },
    getContrats () {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/employee/contracts'
        return window.axios.get(url, config)
    },
    guessContract(idcustomer) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }
        let url = this.baseUrl + 'api-m/einstein/employee/contracts/guess?idcustomer=' + idcustomer;
        return window.axios.get(url, config)
            .then(res => {
                if (res.data.etat == "OK") {
                    if (res.data.data[0]) res.data.data = res.data.data[0];
                }
                return res;
            });
    },
    readNotif(idnotif) {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.post(this.baseUrl + `api-m/einstein/read-notif`, {idnotif}, config);
    },
    getNotifs() {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.accessToken,
                'Content-Type': 'application/json'
            }
        }

        return window.axios.get(this.baseUrl + `api-m/einstein/get-notifs`, config);
    }
}
